import React, { Component } from 'react';

class AboutUs extends Component {
    state = {}
    render() {
        return (
            <div className='m-5 text-center'>
                <h4 className='fw-bold text-center mt-3'>¿Quiénes Somos?</h4>
                <p className='text-start' style={{ whiteSpace: 'pre-wrap' }}>En Autoagencias, reunimos las mejores marcas de autos nuevos en México. Te ofrecemos una experiencia de compra personalizada y transparente, para que elijas con confianza el vehículo que mejor se adapte a ti.
                Con una amplia selección y la última tecnología automotriz, en En la Asociación de Distribuidores de Automotores de Nuevo León, A.C. (ADNL), estamos comprometidos a ayudarte a tomar la decisión correcta. ¡Visítanos y descubre el futuro de la movilidad!</p>
            </div>
        );
    }
}

export default AboutUs;