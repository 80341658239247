import './App.css';
import Header from './components/Header';
import AboutUs from './components/AboutUs';
import VideoHeader from './components/VideoHeader';
import RegisterForm from './components/RegisterForm';
import Footer from './components/Footer';
import SocialBar from './components/SocialBar';
import Location from './components/Location';
import Logo from './components/Logo';
import ImgCarousel from './components/ImgCarousel';

function App() {
  return (
    <>
      <div className='container-fluid p-0 shadowFluid' style={{
        backgroundColor: '#FFFFFF',
        border: '1px solid #0e2a5e',
        borderRadius: '10px',
        maxWidth: '1080px',
        marginTop: '30px',
        marginBottom: '60px',
      }}>
        <Header></Header>
        <RegisterForm></RegisterForm>
        <VideoHeader></VideoHeader>
        <Logo
          maxWidth='300px'
          padding='20px'
        ></Logo>
        <ImgCarousel></ImgCarousel>
        <AboutUs></AboutUs>
        <Location></Location>
        <Logo
          maxWidth='250px'
          padding='20px'
        ></Logo>
        <Footer></Footer>
      </div>
      <SocialBar></SocialBar>
    </>
  );
}

export default App;
