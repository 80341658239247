import React, { Component } from 'react';
import logo from '../assets/images/logo.png'

class Logo extends Component {
    render() {
        return (
            <div className='text-center'>
                <img alt='logoabuot' className='mb-3' src={logo} style={{
                    maxWidth: this.props.maxWidth ?? null,
                    height: this.props.height ?? null,
                    padding:  this.props.padding ?? null,
                }} />
            </div>
        );
    }
}

export default Logo;