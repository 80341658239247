import React, { useEffect, useRef } from 'react';

const VideoHeader = () => {
    const playerRef = useRef(null);

    useEffect(() => {
      if (!window.YT) {
        const tag = document.createElement('script');
        tag.src = 'https://www.youtube.com/iframe_api';
        const firstScriptTag = document.getElementsByTagName('script')[0];
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
      } else {
        loadPlayer();
      }
  
      window.onYouTubeIframeAPIReady = () => {
        loadPlayer();
      };
  
      function loadPlayer() {
        playerRef.current = new window.YT.Player('player', {
          height: '390',
          width: '100%',
          videoId: 'QYHKsn_fUv4',
          playerVars: {
            'playsinline': 1,
            'loop': 1,
            'playlist': 'QYHKsn_fUv4',
            'controls': 0,
            'mute':  1,
          },
          events: {
            'onReady': onPlayerReady,
          }
        });
      }
  
      function onPlayerReady(event) {
        event.target.playVideo();
      }
  
      return () => {
        if (playerRef.current) {
          playerRef.current.destroy();
        }
      };
    }, []);
  
    return (<div className='shadowContainer' id="player"></div>);
};

export default VideoHeader;