import React, { Component } from 'react';
import banner from '../assets/images/banner_mapa.png'

class Location extends Component {
    state = {}
    render() {
        return (
            <div className='shadowContainer' 
            style={{
                backgroundColor: 'rgba(223, 84, 62, 1)'
            }} >
                <a href='https://maps.app.goo.gl/tGSGB5Tj33hrh5U1A' target='_blank'>
                <img src={banner} alt='mapa'
                    style={{
                        width: '100%',
                    }}
                />
                </a>
            </div>
        );
    }
}

export default Location;