import { useState, useEffect } from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import imagesList from '../assets/images/carousel/imagesCarousel.json'

function ImgCarousel() {
    const [images, setImages] = useState([]);
    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };

    useEffect(() => {
        const loadedImages = imagesList.map((image) => require(`../assets/images/carousel/${image}`));
        setImages(loadedImages); // Guarda las rutas de las imágenes en el estado
    }, []);

    return (
        <Carousel
            responsive={responsive}
            autoPlay={true}
            infinite={true}
            containerClass='text-center shadowContainer'
        >
            {
                images.map((image, index) => (
                   <img key={index} src={image} 
                   style={{
                        width:"100%",
                        maxHeight:"250px"
                   }}/>
                ))
            }

        </Carousel>
    );
}

export default ImgCarousel;