import React, { Component } from 'react';

class RegisterForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            responseMsg: '', //'Por el momento el registro no está disponible, mantente atento a nuestras redes sociales.',
            responseType: '',
            showAlert: false,
            isLoading: false

        };
    }

    handleSubmit = (tg) => {
        tg.preventDefault();
        window.gtag_report_conversion();
        this.setState({
            showAlert: false,
            isLoading: true
        });

        const formData = new FormData(tg.target);

        const formJSON = {};
        formData.forEach((value, key) => {
            formJSON[key] = value;
        });

        window.grecaptcha.ready(() => {
            window.grecaptcha.execute('6LdcDT4qAAAAAPp0H8VE9290xoEnUTCTl0yP3rpI', { action: 'submit' })
                .then((token) => {

                    formJSON['token'] = token;
                    fetch("/verify.php", {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json"
                        },
                        body: JSON.stringify(formJSON)
                    })
                        .then((res) => res.json())
                        .then((data) => {
                            console.log(data);
                            if (data.success) {
                                tg.target.reset();
                                this.setState({
                                    isLoading: false,
                                    showAlert: true,
                                    responseType: 'success',
                                    responseMsg: 'Registro Exitoso, en breve recibirás un correo electrónico con la información de tu registro. Si no encuentras el correo, por favor revisa la carpeta de spam.'
                                });
                            } else {
                                this.setState({
                                    isLoading: false,
                                    showAlert: true,
                                    responseType: 'danger',
                                    responseMsg: data.error ?? 'Error en el registro. Inténtalo de nuevo.'
                                });
                            }
                        })
                        .catch((e) => {
                            this.setState({
                                isLoading: false,
                                showAlert: true,
                                responseType: 'danger',
                                responseMsg: 'Error en la comunicación con el servidor. Inténtalo más tarde.'
                            });
                        });
                })
                .catch((e) => {
                    this.setState({
                        isLoading: false,
                        showAlert: true,
                        responseType: 'danger',
                        responseMsg: 'Error al ejecutar reCAPTCHA. Inténtalo más tarde.'
                    });
                });
        });
    }

    render() {
        return (
            <div id='registerform'>
                <div className='p-5'>
                    <h2 className='text-center'>¡Obtén tu entrada gratuita!</h2>
                    <h1 className='fw-bold text-center mb-0'>Registro de Asistencia</h1>
                    <p className='text-center mb-3'>Ingresa tu datos para continuar:</p>
                    <form method='POST' onSubmit={this.handleSubmit}>
                        <div className='mb-3'>
                            <input type="text" required className='form-control' placeholder='Nombre' name="name" />
                        </div>
                        <div className='mb-3'>
                            <input type="email" required className='form-control' placeholder='Correo Electrónico' name="email" />
                        </div>
                        <div className='mb-3'>
                            <input type="text" required className='form-control' placeholder='Teléfono' name="telefono" />
                        </div>
                        <div className='text-center mb-4'>
                            <button className='btn btn-dark' type="submit">{this.state.isLoading ?
                                <div className="spinner-border text-light" style={{fontSize: '10px', height: '20px', width: '20px'}} role="status">
                                    <span className="visually-hidden">Cargando...</span>
                                </div>
                                : "Registrarme"}
                            </button>
                        </div>
                    </form>
                    <div id='form_response' className={"alert alert-" + this.state.responseType + " text-center"} style={{ display: this.state.showAlert ? 'block' : 'none' }} role="alert">
                        {this.state.responseMsg}
                    </div>
                </div>
            </div>
        );
    }
}

export default RegisterForm;